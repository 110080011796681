<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="versionUp-container common-padding">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <el-button class="common-screen-btn common-noMarginLeft" type="primary" @click="showDialog()"
      >上传新版本
    </el-button>
    <!-- <div class="common-upload-container" style="margin: 0">
      <el-button class="common-screen-btn" type="primary">上传新版本</el-button>
      <input
        v-if="!btnLoading"
        class="common-upload-input"
        @input="
          (e) => {
            chooseFile(e);
          }
        "
        type="file"
      />
    </div> -->
    <el-table class="common-table" :data="tableData" :fit="true">
      <el-table-column prop="version" label="版本号" :formatter="tableColumn">
      </el-table-column>
      <el-table-column prop="description" label="描述" :formatter="tableColumn">
      </el-table-column>
      <el-table-column prop="state" label="是否强制更新">
        <template slot-scope="scope">
          <span>{{ scope.row.state == 1 ? "是" : "否" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间" :formatter="tableColumn">
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" :formatter="tableColumn">
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      v-dialogDrag
      title="版本更新"
      :append-to-body="true"
      :visible.sync="dialogShow"
      width="520px"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label=".exe文件" prop="file1Name">
            <el-input
              class="common-screen-input_85"
              :value="ruleForm.file1Name"
            ></el-input>

            <div class="common-upload-container" style="margin: 0">
              <el-button class="common-screen-btn" style="margin: 0" type="primary"
                >选择文件</el-button
              >
              <input
                type="file"
                class="common-upload-input"
                multiple
                @input="(e) => chooseFile(e, 'exe')"
              />
            </div>
          </el-form-item>
          <el-form-item label=".yml文件" prop="file2Name">
            <el-input
              class="common-screen-input_85"
              :value="ruleForm.file2Name"
            ></el-input>
            <div class="common-upload-container" style="margin: 0">
              <el-button class="common-screen-btn" style="margin: 0" type="primary"
                >选择文件</el-button
              >
              <input
                type="file"
                class="common-upload-input"
                multiple
                @input="(e) => chooseFile(e, 'yml')"
              />
            </div>
          </el-form-item>
          <el-form-item label="版本号" prop="version">
            <el-input
              class="common-screen-input_85"
              v-model="ruleForm.version"
            ></el-input>
          </el-form-item>
          <el-form-item label="描述说明" prop="description">
            <el-input
              class="common-screen-input_85"
              v-model="ruleForm.description"
              type="textarea"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否强制更新" prop="state">
            <el-select
              class="common-screen-input_85"
              v-model="ruleForm.state"
              placeholder="请选择"
              filterable
            >
              <el-option key="0" label="否" value="0"> </el-option>
              <el-option key="1" label="是" value="1"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="更新时间" prop="updateTime">
            <el-date-picker
              class="common-screen-input_85"
              v-model="ruleForm.updateTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="dialogShow = false">取 消</el-button>
        <el-button
          class="common-screen-btn"
          type="primary"
          :loading="btnLoading"
          @click="
            () => {
              submitForm('ruleForm');
            }
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { tableColumn } from "../../utils/index.js";
import { versionList, versionUpdate } from "../../service/common.js";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      tableColumn,
      loading: false,
      btnLoading: false,
      dialogShow: false,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "版本", isLink: false },
        { title: "更新记录", isLink: false },
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      ruleForm: {
        file1Name: "",
        file2Name: "",
        version: "",
      },
      rules: {
        file1Name: [{ required: true, message: "请选择文件", trigger: "change" }],
        file2Name: [{ required: true, message: "请选择文件", trigger: "change" }],
        version: [{ required: true, message: "请填写版本号", trigger: "change" }],
        description: [{ required: true, message: "请填写描述说明", trigger: "change" }],
        state: [{ required: true, message: "请选择", trigger: "change" }],
        updateTime: [{ required: true, message: "请选择时间", trigger: "change" }],
      },
      tableData: [],
    };
  },
  created() {
    this.versionList();
  },
  methods: {
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.versionList();
    },
    submitForm(formName) {
      // 提交确认
      let ruleForm = { ...this.ruleForm };
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.btnLoading = true;
          await versionUpdate(ruleForm);
          this.dialogShow = false;
          this.btnLoading = false;
          this.$message.success("上传成功");
          this.versionList();
        } else {
          return false;
        }
      });
    },
    async versionList() {
      this.loading = true;
      let params = { ...this.params };
      let resData = (await versionList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.records;
      this.loading = false;
    },

    async chooseFile(e, type) {
      // 上传文件
      let file = e.target.files[0];
      let fileName = file.name;
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (type == "exe") {
        if (format != "exe" && format != "exe") {
          this.$message.error("请上传exe格式文件");
          e.target.value = "";
          return;
        }
        this.ruleForm.file1 = file;
        this.ruleForm.file1Name = fileName;
        this.ruleForm.version =
          fileName.split(" ")[fileName.split(" ").length - 1].split(".exe")[0] || "";
      } else if (type == "yml") {
        if (format != "yml" && format != "yml") {
          this.$message.error("请上传yml格式文件");
          e.target.value = "";
          return;
        }
        this.ruleForm.file2 = file;
        this.ruleForm.file2Name = fileName;
      }
      e.target.value = "";
      // await versionUpdate({ file: file });
      //   this.btnLoading = false;
      //   e.target.value = "";
      //   this.$message.success("上传成功");
    },
    showDialog() {
      this.ruleForm = {
        file1Name: "",
        file2Name: "",
        version: "",
      };
      if (this.$refs["ruleForm"]) {
        this.$refs["ruleForm"].resetFields();
      }
      this.btnLoading = false;
      this.dialogShow = true;
    },
  },
};
</script>
<style lang="less" scoped>
.versionUp-container {
  text-align: left;
}
</style>
